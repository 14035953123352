


























import { Component, Vue } from "vue-property-decorator";

@Component
export default class EditItem extends Vue {
  get file(): string {
    return this.$route.params.file;
  }

  get settings(): object {
    return this.$store.state.tracker.clients[""][this.file];
  }
}
